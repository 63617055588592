<template>
  <div class="change-password">
    <form
      v-if="!showValidateCode"
      id="forgotPasswordForm"
    >
      <div class="form-group">
        <label for="oldPassword">{{ $t("oldPassword") }}</label>
        <input
          id="oldPassword"
          v-model="oldPassword"
          type="password"
          class="form-control"
          name="oldPassword"
          data-vv-as="old password"
          @blur="validateOldPassword"
          @focus="resetValidator"
        >
        <span
          v-if="errors.has('oldPassword')"
          class="error"
        >{{ errors.first('oldPassword') }}</span>
        <div class="clearfix" />
      </div>
      <div class="form-group">
        <Password
          v-model="password"
          v-validate="rules.password"
          :show-password-criteria="true"
          :label="$t('newPassword')"
          name="password"
          @focus="resetValidator"
        />
        <span
          v-if="errors.has('password')"
          class="error"
        >{{ errors.first('password') }}</span>
      </div>

      <div class="form-group pt-5">
        <button
          :disabled="disabledSubmit"
          type="button"
          class="btn btn-primary"
          @click.prevent="resetPassword"
        >
          {{ $t("change") }}
        </button>
      </div>
    </form>
    <ForgotPasswordLoggedUser
      v-if="showValidateCode && !resetPasswordKey"
      :is-clinician="isClinician"
      @success="onValidateCodeSuccess"
    />
    <ResetPassword
      v-if="resetPasswordKey"
      :is-clinician="isClinician"
      @finished="onPasswordResetSuccess"
    />
  </div>
</template>

<script>
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import ForgotPasswordLoggedUser from '@/components/security/ForgotPasswordLoggedUser'
import ResetPassword from '@/components/security/ResetPassword'
import Password from '@/components/security/Password'

export default {
  name: 'ChangePassword',
  components: {
    ForgotPasswordLoggedUser,
    ResetPassword,
    Password
  },
  mixins: [ErrorsDictionary],
  props: ['isClinician'],
  data () {
    return {
      passwordLength: 12,
      user: null,
      submitting: false,
      showValidateCode: false,
      resetPasswordKey: null,
      password: '',
      oldPassword: '',
      rules: {
        password: ''
      },
      wrongPassword: `${this.$t('wrongPasswordLink.first')} ${this.$t('wrongPasswordLink.second')} ${this.$t('wrongPasswordLink.third')}`
    }
  },
  computed: {
    disabledSubmit () {
      return this.errors.any() || this.submitting || !this.oldPassword || !this.password
    }
  },
  mounted () {
    this.$validator.localize('en', this.errorsDictionary)
    this.user = this.$store.getters.myAccountData
  },
  created () {
    this.enableRules()
  },
  methods: {
    validateOldPassword () {
      return true
    },
    resetValidator () {
      this.$validator.reset()
    },
    resetPassword () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.responseEndedInError = false
          this.responseErrors = []
          this.submitting = true
          const loader = this.$loading.show()
          const data = {
            current_password: this.oldPassword,
            new_password: this.password
          }
          this.$store.dispatch('CHANGE_PASSWORD', data).then((response) => {
            if (response && response.status !== 200) {
              return this.setErrors(response)
            }
            this.$emit('finished')
            this.$toast.success({ message: this.$t('paswordChangeSuccsess') })
            if (!this.isClinician) {
              this.$router.push({ name: 'MyAccount' })
            }
          }).catch((e) => {
            this.$handleApiError(e)
          }).finally(() => {
            this.submitting = false
            loader.hide()
          })
        }
      })
    },
    setErrors (response) {
      Object.keys(response.data.errors).forEach((key) => {
        const field = key === 'current_password' ? 'oldPassword' : 'password'
        const msg = response.data.errors[key][0]
        this.$validator.errors.add(field, msg)
      })
    },
    getAccessCode () {
      if (this.user.contact_primary.email === null && this.user.contact_primary.phone === null) {
        this.$handleApiError(this.$t('noChannelEnabled'))
        return
      }
      const loader = this.$loading.show()
      const data = {
        channel: this.user.contact_primary.email.enabled ? 1 : 2,
        value: this.user.contact_primary.email.enabled ? this.user.contact_primary.email.value : this.user.contact_primary.phone.value,
        forgot_password: true,
        username: this.user.username
      }
      this.$store.dispatch('GET_ACCESS_CODE', data)
        .then(response => {
          if (response && response.status !== 200) {
            this.$handleApiError(response)
          } else {
            if (this.isClinician) {
              this.showValidateCode = true
            } else {
              this.$router.push({ name: 'ForgotPasswordLoggedUser' })
            }
          }
        }).catch((e) => {
          this.$handleApiError(e)
        }).finally(() => loader.hide())
    },
    enableRules () {
      this.rules = {
        password: { required: true, min: 12 }
      }
    },
    onValidateCodeSuccess (key) {
      this.resetPasswordKey = key
    },
    onPasswordResetSuccess () {
      this.$emit('finished')
    }
  }
}
</script>
